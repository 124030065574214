import { Box, Flex, useColorMode } from '@chakra-ui/react';
import React from 'react';
import Footer from '../common/footer';
import TopBar from '../common/topbar';

export function Layout({ children }) {
  const {colorMode}= useColorMode()
  return (
    <Box>
      <Flex flexDirection="column">
        {/* <Sidebar /> */}
        <Box background={colorMode === "light"?"white":"black"} w="100%" minH="calc(100vh - 85px)">
          <TopBar />
          <Box background="white">
            {children}
          </Box>
        </Box>
        <Box>
        <Footer height="85px"/>
        </Box>
      </Flex>
    </Box>
  );
}

export const noop = 'value';
