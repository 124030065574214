import { Router } from '@reach/router';
import React, { lazy } from 'react';
import PublicRoute from './components/public-route';



const NotFoundPage = lazy(() => import('./layouts/404'));
const HomePage = lazy(() => import('./layouts/home'));
const LoginPage = lazy(() => import('./components/login'));
const UserProfilePage = lazy(() => import('./layouts/user-profile'));
const CategoryPage = lazy(() => import('./layouts/category'));
const AvailabilitySearchPage = lazy(() =>
  import('./layouts/availabilitysearch'),
);
const ProductDetailsPage = lazy(() => import('./layouts/product-details'));
const Cart = lazy(() => import('./layouts/cart'));

const Checkout = lazy(() => import('./layouts/checkout'));
const Confirmation = lazy(() => import('./layouts/confirmation'));
const StoreLocator = lazy(() => import('./layouts/store-locator'));
const StoreInfo = lazy(() => import('./layouts/storeInfo'));
const ForgotPasswordPage = lazy(() =>
  import('./components/auth/forget-password'),
);
const AboutUs = lazy(() => import('./layouts/about-us'));
const Careers = lazy(() => import('./layouts/careers'));
const FAQ = lazy(() => import('./layouts/faq'));
const TermsOfUse = lazy(() => import('./layouts/terms-of-use'));
const PrivacyPolicy = lazy(() => import('./layouts/privacy-policy'));
const FilterPage = lazy(() => import('./layouts/filter'));

export const ROUTE_NAMES = {
  AUTH: '/login',
  HOME: '/',
  FILTER: '/filter/:retailertype/:retailerid',
  PROFILE: '/profile',
  FORGOTPASSWORD: '/forgot',
};

const Routes = () => (
  <>
    <Router>
      <PublicRoute path={ROUTE_NAMES.AUTH} component={LoginPage} />
      <PublicRoute
        path={ROUTE_NAMES.FORGOTPASSWORD}
        component={ForgotPasswordPage}
      />
      <PublicRoute path={ROUTE_NAMES.HOME} component={HomePage} />
      <PublicRoute path={ROUTE_NAMES.FILTER} component={FilterPage} />
      <PublicRoute path="/signin/" component={HomePage} />
      <PublicRoute path="/signout/" component={HomePage} />
      <PublicRoute path="/userprofile/:activeTab" component={UserProfilePage} />
      <PublicRoute path="/userprofile/*" component={UserProfilePage} />
      <PublicRoute
        path="/category/:parentCategoryName/:categoryName"
        component={CategoryPage}
      />
      <PublicRoute
        path="/category/:parentCategoryName/:categoryName/storeInfo/:storeName"
        component={CategoryPage}
      />
      <PublicRoute
        path="/Brand/:brand/:storeName"
        component={CategoryPage}
      />
      <PublicRoute
        path="/category/:parentCategoryName"
        component={CategoryPage}
      />
       <PublicRoute
        path="/category/:parentCategoryName/storeInfo/:storeName"
        component={CategoryPage}
      />
      <PublicRoute
        path="/price/:price"
        component={CategoryPage}
      />
      <PublicRoute
        path="/discountAmount/:direction/:discountAmount"
        component={CategoryPage}
      />
      <PublicRoute
        path="/discountPercent/:direction/:discountPercent"
        component={CategoryPage}
      />
        <PublicRoute
        path="/country/:country"
        component={CategoryPage}
      />
      <PublicRoute
        path="/Brand/:brand"
        component={CategoryPage}
      />
      <PublicRoute
        path="/Brand/:brand/Bucketfilter"
        component={CategoryPage}
      />
      <PublicRoute
        path="/country/:country/Bucketfilter"
        component={CategoryPage}
      />
      <PublicRoute
        path="/price/:price/Bucketfilter"
        component={CategoryPage}
      />
       <PublicRoute
        path="/discountAmount/:direction/:discountAmount/Bucketfilter"
        component={CategoryPage}
      />
       <PublicRoute
        path="/discountPercent/:direction/:discountPercent/Bucketfilter"
        component={CategoryPage}
      />
       <PublicRoute
        path="/category/:parentCategoryName/Bucketfilter"
        component={CategoryPage}
      />
      <PublicRoute
        path="/category/:parentCategoryName/:categoryName/Bucketfilter"
        component={CategoryPage}
      />
      <PublicRoute
        path="/store/:storeId"
        component={CategoryPage}
      />
      <PublicRoute
        path="/storeinfo/:storeName/:storeId"
        component={StoreInfo}
      />
       <PublicRoute
        path="/storelocator/:storeName/:storeId/:category"
        component={CategoryPage}
      />
      <PublicRoute
        path="/storelocator/:storeName/:storeId"
        component={CategoryPage}
      />
      <PublicRoute
        path="/search/storeInfo/:category/:searchKeyword/:storeName"
        component={CategoryPage}
      />
      <PublicRoute path="/search/:category/:searchKeyword" component={CategoryPage} />

      {/* <PublicRoute path="/products/:category/:prodFullName/:imageSrc" component={ProductDetailsPage} /> */}
      <PublicRoute path="/products/:category/:prodFullName/:storePosName/:storeSizeUom/:searchKeyword" component={ProductDetailsPage} />
      <PublicRoute path="/products/:category/:prodFullName/:storePosName/:storeSizeUom/:containerType/:packUnits/:searchKeyword" component={ProductDetailsPage} />
      <PublicRoute path="/product/:category/:prodFullName/:storePosName/:storeSizeUom/:containerType/:packUnits/searchKeyword/storeInfo/:storeName" component={ProductDetailsPage} />
      <PublicRoute path="/product/:category/:prodFullName/:storePosName/:storeSizeUom/:storeItemQty/:searchKeyword/:storeName" component={ProductDetailsPage} />
      <PublicRoute path="/products/:category/:prodFullName/:storePosName/:storeSizeUom/:storeItemQty/:searchKeyword" component={ProductDetailsPage} />
      <PublicRoute path="/products/:category/:prodFullName/:storePosName/:storeSizeUom/:storeItemQty/:searchKeyword/storeInfo/:storeName" component={ProductDetailsPage} />
      <PublicRoute path="/products/:category/:prodFullName/:searchKeyword" component={ProductDetailsPage} />
      <PublicRoute path="/products/:category/:prodFullName" component={ProductDetailsPage} />
      <PublicRoute path="/product/:category/:prodFullName/:storePosName/:storeSizeUom/:storeDisplayQty" component={ProductDetailsPage} />
      <PublicRoute path="/product/:category/:prodFullName/:storePosName/:storeSizeUom" component={ProductDetailsPage} />
      <PublicRoute path="/product/:category/:prodFullName/:storePosName/:storeSizeUom/searchKeyword/:searchKeyword" component={ProductDetailsPage} />
      <PublicRoute path="/product/:category/:prodFullName/:storePosName/:storeSizeUom/:storeItemQty/searchKeyword/:searchKeyword" component={ProductDetailsPage} />
      <PublicRoute path="/product/:category/:prodFullName/:storePosName/:storeSizeUom/:containerType/:packUnits/searchKeyword/:searchKeyword" component={ProductDetailsPage} />
      <PublicRoute path="/products/:category/:prodFullName/:storePosName/:storeSizeUom/:containerType/:packUnits/:searchKeyword/:storeName" component={ProductDetailsPage} />
      <PublicRoute path="/products/:category/:prodFullName/:storePosName/:storeSizeUom/:containerType/:storeItemQty/searchKeyword/:searchKeyword/storeInfo/:storeName" component={ProductDetailsPage} />
      <PublicRoute path="/storelocator" component={StoreLocator} />
      <PublicRoute path="/storeinfo" component={StoreInfo} />
      <PublicRoute path="/cart/:cartId" component={Cart} />
      <PublicRoute path="/checkout/*" component={Checkout} />
      <PublicRoute path="/checkout/:cartId" component={Checkout} />
      <PublicRoute path="/confirmation/:orderId" component={Confirmation} />
      <PublicRoute
        path="/availabilitysearch/:cartId"
        component={AvailabilitySearchPage}
      />
      <PublicRoute default component={NotFoundPage} />
      <PublicRoute path="/about-us" component={AboutUs} />
      <PublicRoute path="/careers" component={Careers} />
      <PublicRoute path="/faq" component={FAQ} />
      <PublicRoute path="/terms-of-use" component={TermsOfUse} />
      <PublicRoute path="/privacy-policy" component={PrivacyPolicy} />
    </Router>
  </>
);

export default Routes;
